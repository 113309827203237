<template>
  <transition-group name="slide-up">
    <div v-if="isOpen" :key="1" class="z-30 fixed inset-x-0 top-10 bottom-0 dialog-slider">
      <div class="absolute inset-0 -top-8 w-full h-full bg-black opacity-50 z-10" />

      <div class="[ flex flex-col lg:hidden ] [ absolute bottom-14 ] z-20 w-full bg-primary-50 px-6 pb-16">
        <button
          class="text-sm [ flex items-center justify-center ] [ w-8 h-8 ] [ absolute top-8 right-6 ] z-50"
          @click="close"
        >
          <svg-icon-close class="text-primary-700" width="28" height="28" />
        </button>

        <div v-if="user" class="relative py-8 overflow-y-auto">
          <header class="w-full [ text-primary-700 relative ] [ pb-8 z-0 ] [ flex flex-col justify-between ]">
            <p class="text-system-gray capitalize text-lg-1 mb-2">
              {{ $t('welcome') }}
            </p>
            <h2 class="text-1.5xl font-bold truncate">{{ user.firstname }} {{ user.lastname }}</h2>
          </header>

          <ul class="AccountMenu text-tiny">
            <li class="AccountMenu__Item">
              <button
                :aria-label="$t('accountOverview')"
                class="flex w-full items-center justify-between"
                @click="navigateTo('/account')"
              >
                <div class="flex items-center">
                  <svg-icon-account-user alt="category image" class="h-6 w-6 mr-4 object-contain" />
                  <span class="font-normal text-base"> {{ $t('accountOverview') }} </span>
                </div>
                <svg-icon
                  name="chevron-right"
                  class="float-right text-primary-800 stroke-current"
                  :class="{
                    'transform scale-x-reverse': $i18n.locale === 'ar',
                  }"
                  width="16"
                  height="16"
                />
              </button>
            </li>
            <li class="AccountMenu__Item">
              <button
                :aria-label="$t('myWishlist')"
                class="flex w-full items-center justify-between"
                @click="navigateTo('/account/wishlist')"
              >
                <div class="flex items-center">
                  <svg-icon-account-favorite alt="category image" class="h-6 w-6 mr-4 object-contain" />
                  <span class="font-normal text-base"> {{ $t('myWishlist') }} </span>
                </div>
                <svg-icon-chevron-right
                  class="float-right text-primary-800 stroke-current"
                  :class="{
                    'transform scale-x-reverse': $i18n.locale === 'ar',
                  }"
                  width="16"
                  height="16"
                />
              </button>
            </li>
            <li class="AccountMenu__Item">
              <button
                :aria-label="$t('myOrders')"
                class="flex w-full items-center justify-between"
                @click="navigateTo('/account/orders')"
              >
                <div class="flex items-center">
                  <svg-icon-account-orders alt="category image" class="h-6 w-6 mr-4 object-contain" />
                  <span class="font-normal text-base"> {{ $t('myOrders') }} </span>
                </div>
                <svg-icon-chevron-right
                  class="float-right text-primary-800 stroke-current"
                  :class="{
                    'transform scale-x-reverse': $i18n.locale === 'ar',
                  }"
                  width="16"
                  height="16"
                />
              </button>
            </li>

            <li class="AccountMenu__Item">
              <button
                :aria-label="$t('myAddressBook')"
                class="flex w-full items-center justify-between"
                @click="navigateTo('/account/addresses')"
              >
                <div class="flex items-center">
                  <svg-icon-account-address alt="category image" class="h-6 w-6 mr-4 object-contain" />
                  <span class="font-normal text-base"> {{ $t('myAddressBook') }} </span>
                </div>
                <svg-icon-chevron-right
                  class="float-right text-primary-800 stroke-current"
                  :class="{
                    'transform scale-x-reverse': $i18n.locale === 'ar',
                  }"
                  width="16"
                  height="16"
                />
              </button>
            </li>

            <li class="AccountMenu__Item">
              <button
                :aria-label="$t('accountSetting')"
                class="flex w-full items-center justify-between"
                @click="navigateTo('/account/edit')"
              >
                <div class="flex items-center">
                  <svg-icon-account-settings alt="category image" class="h-6 w-6 mr-4 object-contain" />
                  <span class="font-normal text-base"> {{ $t('accountSetting') }} </span>
                </div>
                <svg-icon-chevron-right
                  class="float-right text-primary-800 stroke-current"
                  :class="{
                    'transform scale-x-reverse': $i18n.locale === 'ar',
                  }"
                  width="16"
                  height="16"
                />
              </button>
            </li>
          </ul>

          <div class="my-8 w-full h-1 bg-primary-1-15" />
          <button :aria-label="$t('logout')" class="flex w-full items-center justify-between" @click="logout">
            <div class="flex items-center">
              <svg-icon-account-logout class="mr-4" width="24" height="24" />
              <span class="font-normal text-system-red text-base"> {{ $t('logout') }} </span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script setup lang="ts">
const { user } = useAuth();
const { logout } = useLogout();
const { emit } = useEventBus();
const isOpen = ref(false);
const { redirect } = useAppRouter();
const route = useRoute();

const { t: $t } = useI18n({
  useScope: 'local',
});

useEvent('TOGGLE_ACCOUNT_MENU', (value: boolean) => {
  isOpen.value = value;
});

function close() {
  emit('TOGGLE_ACCOUNT_MENU', false);
}

function navigateTo(url: string) {
  close();
  redirect(url);
}

watchEffect(() => {
  if (process.server) {
    return;
  }

  window.document.body.classList.toggle('overflow-hidden', isOpen.value);
});

onBeforeUnmount(() => window.document.body.classList.toggle('overflow-hidden', false));
</script>

<style lang="postcss" scoped>
.AccountMenu > li:not(:last-of-type) {
  &::after {
    @apply flex flex-col bg-primary-1-15 my-5;
    content: '';
    width: 100%;
    height: 1px;
  }
}

.dialog-slider {
  @apply pb-20;
  height: calc(var(--screen-height) - 24px);
}
</style>

<i18n>
{
  "en": {
    "welcome": "Welcome,",
    "close":"Close",
    "accountOverview": "Account Overview",
    "myWishlist": "My Wishlist",
    "myOrders": "My Orders",
    "myAddressBook": "My Address Book",
    "accountSetting": "Account Settings",
    "logout": "Logout",
    "navigateTo": "navigate to"
  },
  "ar": {
    "welcome": "مرحباً،",
    "close":"اغلاق",
    "accountOverview": "نظرة عامة على الحساب",
    "myWishlist": "قائمة امنياتي",
    "myOrders": "طلباتي",
    "myAddressBook": "دفتر عناويني",
    "accountSetting": "إعدادات الحساب",
    "logout": "تسجيل الخروج",
    "navigateTo": "الانتقال إلى"
  }
}
</i18n>
