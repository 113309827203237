<template>
  <header
    data-cy-region="header"
    data-attr="header"
    class="fixed top-0 inset-x-0 main-navigation z-30 bg-white text-black"
  >
    <div class="relative">
      <div class="bg-primary-2-30 px-5 h-10 flex items-center">
        <p class="container mx-auto text-tiny text-center uppercase">
          {{ $t('notice') }}
        </p>
      </div>

      <div class="[ flex items-center flex-wrap md:grid md:grid-cols-1 ]">
        <div class="[ header-title ] [ container mx-auto ] md:py-4">
          <div></div>
          <div class="header-mobile relative w-full">
            <AppLink to="/" data-attr="header__home__link" class="w-full header-logo">
              <svg-icon-logo class="w-25 md:w-36 h-4 md:h-5 mx-auto" />
            </AppLink>
            <div class="md:hidden w-full px-5 md:px-0">
              <SearchDialog class="header-search w-full h-16" />
            </div>
          </div>

          <div class="flex items-center md:space-x-6">
            <QuickSearch class="hidden md:flex md:mt-0 ml-auto z-20" />
            <AppLink
              data-attr="header__logIn__link"
              :to="!isLoggedIn ? '/login' : '/account'"
              class="ml-auto hidden order-1 lg:order-2 md:flex items-center"
            >
              <svg-icon-user class="h-4 w-4" />
            </AppLink>

            <AppLink
              to="/cart"
              data-attr="header__cartIcon__btn"
              class="order-4 ml-10 relative hidden md:flex items-center justify-center cursor-pointer"
            >
              <svg-icon-cart width="16" height="16" :class="{ 'text-black stroke-current': !cartCount }" />
              <span v-show="cartCount" data-attr="header__cartItemCount" class="CartIconCount">({{ cartCount }})</span>
              <ComposeAddToCartAlert />
            </AppLink>
          </div>
        </div>

        <div class="container flex">
          <ClientOnly>
            <CategoryMegaMenu class="w-full" />
          </ClientOnly>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
const { isLoggedIn } = useAuth();
const { count: cartCount } = useCartAttributes();
const { emit } = useEventBus();
const { t: $t } = useI18n({
  useScope: 'local',
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function openAsideCart() {
  emit('TOGGLE_ASIDE_CART', true);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function openSideMenu() {
  emit('TOGGLE_SIDE_MENU', true);
}
</script>

<style lang="postcss" scoped>
.CartIconCount {
  @apply text-sm absolute flex items-center justify-center;
  width: 20px;
  height: 20px;
  bottom: 2px;
  right: -20px;
}

.nuxt-icon svg {
  width: 27px;
  height: 27px;
}
</style>

<i18n>
{
  "en": {
    "loginOrRegister": "Login or Register",
    "contactUs": "Contact us",
    "switchLang": "عربي",
    "logout":  "Logout",
    "notice": "FREE shipping on orders above 750 EGP",
    "customerSupport": "Customer Support",
    "greet": "Hello, {name}"
  },
  "ar": {
    "contactUs": "اتصل بنا",
    "switchLang": "English",
    "logout":  "تسجيل الخروج",
    "notice": "شحن مجاني للطلبات التي تتخطى ٧٥٠ جنيه",
    "customerSupport": "دعم العملاء",
    "loginOrRegister": "تسجيل الدخول",
    "greet": "مرحبا، {name}"
  }
}
</i18n>

<style lang="postcss" scoped>
@screen md {
  .header-title {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.header-mobile {
  @apply px-6 lg:px-0;
  display: grid;
  grid-template-columns: 124px 1fr;
  align-items: center;
}

@screen lg {
  .header-title {
    display: grid;
    grid-template-columns: 450px 1fr 450px;
  }
  .header-mobile {
    grid-template-columns: 1fr;
  }
}

:deep(.nuxt-icon svg) {
  width: 25px;
  height: 25px;
}
</style>
