<template>
  <transition-group name="slide-up">
    <div
      v-if="isOpen"
      :key="1"
      class="z-30 fixed inset-x-0 bottom-0 top-10 w-full dialog-slider flex flex-col bg-white lg:hidden"
    >
      <button
        class="text-sm [ flex items-center justify-center ] [ w-8 h-8 ] [ absolute top-6 right-6 ] z-50"
        :title="$t('closeMenu')"
        @click="close"
      >
        <svg-icon-close class="text-primary-700" width="28" height="28" />
      </button>
      <div class="relative pt-5 pb-20 h-full overflow-y-auto">
        <header class="w-full relative [ pb-7 px-6 z-0 ] [ flex flex-col justify-between ]">
          <h3 class="text-1.5xl font-bold capitalize">{{ $t('title') }}</h3>
        </header>

        <ul class="Category__Menu px-6">
          <li class="Category__Menu__Item">
            <AppLink to="/deals" class="flex items-center justify-between h-full text-deals-100">
              <span class="flex items-center">
                <span class="flex items-center justify-center rounded-full w-10 h-10 bg-deals-05 mr-4">
                  <span class="flex items-center justify-center rounded-full w-8 h-8 bg-deals-10">
                    <svg-icon-sale-active width="24" height="24" class="w-6 h-6" />
                  </span>
                </span>
                {{ $t('sale') }}
              </span>
              <svg-icon-chevron-right
                class="float-right text-primary-800 stroke-current"
                :class="{
                  'transform scale-x-reverse': $i18n.locale === 'ar',
                }"
                width="16"
                height="16"
              />
            </AppLink>
          </li>

          <li class="Category__Menu__Item">
            <AppLink to="/new-in" class="flex items-center justify-between h-full text-primary-800">
              <svg-icon-new-in width="35" height="35" class="mr-5" />
              {{ $t('newIn') }}
              <svg-icon-chevron-right
                class="float-right text-primary-800 stroke-current ml-auto"
                :class="{
                  'transform scale-x-reverse': $i18n.locale === 'ar',
                }"
                width="16"
                height="16"
              />
            </AppLink>
          </li>
          <li v-for="category in categories" :key="category?.id || ''" class="Category__Menu__Item">
            <button
              v-if="category.children && category.children.length"
              :aria-label="`navigate to ${category.name}`"
              class="flex w-full items-center justify-between"
              @click="
                selectedMenuItem = {
                  type: 'Category',
                  payload: category,
                }
              "
            >
              <div class="flex items-center">
                <img
                  v-if="category.image"
                  :src="category.image"
                  alt="category image"
                  class="h-14 w-10 mr-4 object-contain"
                />
                <span class="text-sm"> {{ category.name }} </span>
              </div>
              <svg-icon-chevron-right
                class="float-right text-primary-800 stroke-current"
                :class="{
                  'transform scale-x-reverse': $i18n.locale === 'ar',
                }"
                width="16"
                height="16"
              />
            </button>
            <button v-else class="flex w-full items-center justify-between" @click="navigateTo(`/${category.url_key}`)">
              <div class="flex items-center">
                <span class=""> {{ category.name }} </span>
              </div>
              <svg-icon-chevron-right
                class="float-right text-primary-800 stroke-current"
                :class="{
                  'transform scale-x-reverse': $i18n.locale === 'ar',
                }"
                width="16"
                height="16"
              />
            </button>
          </li>
        </ul>
      </div>
      <transition name="slide-up">
        <section v-if="selectedMenuItem" :key="2" class="StackMenu z-40 bg-white overflow-y-auto pt-8 pb-28">
          <template v-if="selectedMenuItem">
            <button class="px-6 flex items-center" @click="selectedMenuItem = null">
              <svg-icon-chevron-right
                class="float-right text-primary-800 stroke-current mr-3"
                :class="{
                  'transform scale-x-reverse': $i18n.locale === 'en',
                }"
                width="16"
                height="16"
              />
              <span class="text-sm uppercase underline font-semibold">{{ $t('allCategories') }}</span>
            </button>
            <header class="w-full [ pb-7 px-6 mt-7 z-0 ] [ flex flex-col justify-between ]">
              <h3 class="text-1.5xl font-bold capitalize">
                {{ selectedMenuItem?.payload?.name || '' }}
              </h3>
            </header>

            <nav class="w-full relative z-40">
              <ul class="text-secondary-900 Subategory__Menu px-6 mt-8">
                <li v-for="(category, index) in (selectedMenuItem?.payload as Category)?.children" :key="index">
                  <button
                    class="flex flex-col w-full justify-between"
                    @click="navigateTo(`/category/${category.url_key}`)"
                  >
                    <span class="mt-2 category-name"> {{ category.name }} </span>
                  </button>
                </li>
              </ul>
            </nav>

            <button
              class="flex px-6 md:px-0 w-full justify-between items-center tex-sm uppercase font-semibold underline"
              @click="navigateTo(`/category/${selectedMenuItem?.payload?.url_key}`)"
            >
              {{ $t('shopAll', { name: selectedMenuItem?.payload?.name }) }}
            </button>
          </template>
        </section>
      </transition>
    </div>
  </transition-group>
</template>

<script setup lang="ts">
import { Category } from '@robustastudio/e-commerce/dist/runtime';
import { Brand } from '@robustastudio/e-commerce/dist/runtime/graphql/Wishlist';

type MenuItemSelection = {
  type: 'Category' | 'Brand';
  payload: Brand | Category | null;
};

const isOpen = ref(false);
const { data } = useAsyncData(async () => {
  const payload = await useCategories();

  const brands = await useBrands();

  return {
    flatCategories: payload.flatCategories,
    brands: brands.brands,
  };
});

const flatCategories = computed(() => data.value?.flatCategories || []);

const { redirect } = useAppRouter();

const { emit } = useEventBus();

const selectedMenuItem = ref<MenuItemSelection | null>(null);

const { t: $t } = useI18n({
  useScope: 'local',
});

useEvent('TOGGLE_CATEGORY_MENU', (value: boolean) => {
  isOpen.value = value;
});

function close() {
  emit('TOGGLE_CATEGORY_MENU', false);
}

function navigateTo(url: string) {
  close();
  redirect(url);
}

watchEffect(() => {
  if (process.server) {
    return;
  }

  window.document.body.classList.toggle('overflow-hidden', isOpen.value);
});

onBeforeUnmount(() => window.document.body.classList.toggle('overflow-hidden', false));

const categories = computed(() => {
  return toRaw(flatCategories.value)
    ?.filter(category => category.includeInMenu && category.level === 1)
    .slice(0, 9);
});
</script>

<style lang="postcss" scoped>
.Category__Menu > li:not(:last-of-type)::after {
  @apply flex flex-col bg-primary-100 my-5;
  content: ' ';
  width: 100%;
  height: 1px;
}
.Subategory__Menu > li::after {
  @apply flex flex-col bg-primary-100 my-5;
  content: ' ';
  width: 100%;
  height: 1px;
}

.StackMenu {
  @apply absolute bottom-0  right-0;
  top: 0;
  width: 100vw;
  /* min-height: var(--window-height); */
  height: calc(var(--screen-height) - 124px);
}

.category-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.dialog-slider {
  @apply pb-20;
  height: calc(var(--screen-height) - 24px);
}
</style>

<i18n>
{
  "en": {
    "title": "Shop By Category",
    "categories": "Categories",
    "close":"Close",
    "closeMenu":"Close Menu",
    "whatYouLookingFor": "What are you Looking For ?",
    "shopByBrand": "Shop By Brand",
    "shopAllBrands": "Shop all BRANDS A-Z",
    "ourPartners": "Our Partners in success",
    "allCategories": "All Categories",
    "shopAll": "Shop All {name}",
    "sale": "Sale",
    "newIn": "New In"
  },
  "ar": {
    "title": "الفئات",
    "categories": "الفئات",
    "close":"اغلاق",
    "closeMenu":"القائمة اغلاق",
    "whatYouLookingFor": "عن ماذا تبحث بالضبط?",
    "shopByBrand": "تصفح حسب الماركه",
    "shopAllBrands": "تسوق جميع الماركات",
    "ourPartners": "شركاؤنا فى النجاح ",
    "allCategories": "جميع الفئات",
    "shopAll": "تسوق جميع {name}",
    "sale": "تخفيضات",
    "newIn": "جديد"
  }
}
</i18n>
