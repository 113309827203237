<template>
  <div class="AddToCartAlert p-5 absolute bg-primary-700 flex flex-col" role="alert">
    <button class="ml-auto AddToCartAlert__close pointer-events-auto" type="button" :title="t('close')" @click="close">
      <svg-icon-close class="text-white" width="17" height="15" />
    </button>

    <p class="mt-1 text-white text-sm text-left">{{ t('itemAdded', { name: product.name }) }}</p>

    <AppButton as="AppLink" to="/cart" class="mt-3 w-full text-center pointer-events-auto justify-center" white>
      {{ t('viewCart') }}
    </AppButton>
  </div>
</template>

<script setup lang="ts">
import { ProductData } from '@robustastudio/e-commerce/dist/runtime/composables/product';
import { PropType } from 'vue';

const { t } = useI18n({
  useScope: 'local',
});

defineProps({
  product: {
    type: Object as PropType<ProductData | null>,
    default: () => null,
  },
});

const emit = defineEmits(['close']);

function close() {
  emit('close');
}
</script>

<style lang="postcss" scoped>
.AddToCartAlert {
  @apply top-10;
  width: calc(100vw - 60px);
  height: auto;
  right: -15px;

  &::before {
    @apply bg-primary-1-100 absolute;
    content: '';
    right: 20px;
    top: -7px;
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
  }

  &__close {
    @apply outline-none;
  }
}

@screen lg {
  .AddToCartAlert {
    width: 362px;
  }
}
</style>

<i18n>
{
  "en": {
    "itemAdded": "{name} has been added to cart",
    "viewCart": "View Cart",
    "close": "Close"
  },
  "ar": {
    "itemAdded": "تمت اضافة {name} الى عربة التسوق",
    "viewCart": "عربة التسوق",
    "close": "اغلاق"
  }
}
</i18n>
