<template>
  <footer data-cy-region="footer" class="mt-8 mb-36 md:mb-8">
    <div>
      <div class="mx-auto max-w-xs text-center">
        <form class="NewsletterForm md:mt-2" @submit="onSubmit">
          <TextInput
            id="newsletter_email"
            name="email"
            :label="$t('subscribe')"
            class="mt-2 mb-2 text-primary-1-100"
            type="email"
            :placeholder="$t('addYourEmail')"
            aria-label="Email Address"
            rules="email"
            :filled="false"
            border-bottom
            light
          />
          <AppButton :disabled="!meta.valid" :loading="isSubmitting" variant="outline" class="mx-auto" disable-hover>
            {{ $t('subscribeCta') }}
          </AppButton>
        </form>
      </div>
    </div>

    <div class="FooterLinks container mx-auto mt-10 mb-8 flex justify-center gap-4 flex-wrap text-sm">
      <AppLink to="/contact" class="hover:underline">{{ $t('contactUs') }}</AppLink>
      <AppLink to="/Stores" class="hover:underline">{{ $t('stores') }}</AppLink>
      <AppLink to="/faqs" class="hover:underline">{{ $t('faqs') }}</AppLink>
      <AppLink to="/about" class="hover:underline">{{ $t('who') }}</AppLink>
      <AppLink to="/privacy-policy" class="hover:underline">{{ $t('privacyPolicy') }}</AppLink>
      <AppLink to="/terms-and-conditions" class="hover:underline">{{ $t('termsAndConditions') }}</AppLink>
    </div>
    <div class="container mx-auto md:grid md:grid-cols-3">
      <div class="mb-8 md:mb-0 text-center md:text-left">
        <a
          :href="switchLocalePathWithParams($i18n.locale === 'en' ? 'ar' : 'en')"
          class="text-sm"
          :class="[$i18n.locale === 'en' ? 'font-body-ar font-semibold' : 'font-body']"
          >{{ $t('switchLang') }}</a
        >
      </div>
      <ul class="flex gap-x-6 justify-center items-center">
        <li>
          <a href="https://www.facebook.com/parfoisegypt/" aria-label="Like us on Facebook">
            <span class="hidden">Like us on Facebook</span>
            <svg-icon-facebook width="16" height="16" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/parfoisegypt/?hl=en" aria-label="Follow us on Instagram">
            <span class="hidden">Follow us on Instagram</span>
            <svg-icon-instagram width="16" height="16" />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/PARFOIS_" aria-label="Follow us on Twitter">
            <span class="hidden">Follow us on Twitter</span>
            <svg-icon-twitter width="16" height="16" />
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script setup lang="ts">
defineComponent({
  name: 'TheFooterMainSection',
});
const switchLocalePath = useSwitchLocalePath();

const route = useRoute();

// To keep query params if exists
function switchLocalePathWithParams(locale: string) {
  const queryParams = route.fullPath.split('?')[1] ? `?${route.fullPath.split('?')[1]}` : '';
  return `${switchLocalePath(locale)}${queryParams}`;
}

const { t: $t } = useI18n({ useScope: 'local' });
const { t } = useI18n({ useScope: 'global' });

import { CombinedError } from 'villus';
import { object, string } from 'yup';

const { submitForm } = useSubscribeToNewsletter();

const { success, error } = useAlerts();

const { isSubmitting, handleSubmit, meta, resetForm, setErrors } = useForm({
  validationSchema: object({
    email: string()
      .required(t('validation.required'))
      .email(t('validation.email'))
      .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, t('validation.email')),
  }),
});

const onSubmit = handleSubmit(async ({ email }) => {
  try {
    await submitForm(email);
    success($t('success').toString(), $t('newsLetterSuccess').toString());
    resetForm();
  } catch (e) {
    if (e instanceof CombinedError) {
      error('error', e.message);
      setErrors({
        email: $t('invalidEmail').toString(),
      });
    }
  }
});
</script>

<style lang="postcss" scoped>
a {
  font-size: 13px;
}

footer {
  @screen md {
    height: 100%;
  }

  .FooterLinks {
    @screen lg {
      grid-template-columns: 1fr 1fr 1fr 1fr 360px;
    }

    a {
      @apply relative;

      &::before {
        content: '';
        width: calc(100% + 10px);
        transform: scaleX(0);
        @apply absolute bottom-0 right-0 left-0 text-secondary-700;
        box-shadow: 0px 2px 0px 5px currentColor;
        transition: transform 0.3s ease-in-out;
      }

      /* &:hover {
        &::before {
          transform: scaleX(1);
        }
      } */
    }

    & > div:not(:last-child) {
      @apply relative;
    }
    & > div:not(:last-child)::after {
      @apply mx-auto;
      @screen md {
        content: unset;
      }
      height: 1px;
      width: calc(100% - 50px);
      background-color: #d0dedc;
      position: absolute;
      bottom: -24px;
      right: 0;
      left: 0;
    }
  }
}
:deep(.TextInput__label) {
  @apply font-bold text-lg;
  color: #000;
}
</style>

<i18n>
{
  "en": {
    "customerService": "Customer Service",
    "faqs": "FAQs",
    "contactUs": "Contacts",
    "about": "About Parfois",
    "who": "Who are we?",
    "stores": "Find Stores",
    "more": "More",
    "refunds": "Returns & Refund",
    "termsAndConditions": "Terms & Conditions",
    "subscribe": "Join our newsletter",
    "follow": "Follow us",
    "copyrights": "All Rights Reserved",
    "changeLang": "Change Language",
    "switchLang": "عربي",
    "downloadApp": "Download App",
    "weAccept": "We Accept",
    "careers": "Careers",
    "privacyPolicy": "Privacy Policy",
    "newsletter": "Newsletter",
    "beTheFirstToKnow": "BE THE FIRST TO KNOW",
    "note": "Sign up to newsletter to get latest updates about new products and exclusive discounts",
    "email": "E-mail Address",
    "addYourEmail": "ex{'@'}gmail.com",
    "partnerBrand": "Partners",
    "success": "Success",
    "newsLetterSuccess": "Subscribed to newsletter successfully!",
    "error": "Subscribe Error",
    "returns": "Refunds & Returns ",
    "appName": "Parfois",
    "send": "Send",
    "subscribeCta": "Register",
    "invalidEmail": "Invalid Email"
  },
  "ar": {
    "customerService": "خدمة العملاء",
    "faqs": "الأسئلة الشائعة",
    "contactUs": "اتصل بنا",
    "about": "عن الشركة",
    "who": "من نحن؟",
    "stores": "الفروع",
    "more": "المزيد",
    "refunds": "الاسترجاع",
    "termsAndConditions": "سياسة الاستخدام",
    "subscribe": "سجل للنشرة",
    "follow": "تابعنا",
    "copyrights": "جميع الحقوق محفوظة",
    "switchLang": "English",
    "downloadApp": "تحميل التطبيق",
    "weAccept": "نحن نقبل",
    "careers": "المهن",
    "privacyPolicy": "سياسة الخصوصية",
    "newsletter": "- النشرة الإخبارية",
    "beTheFirstToKnow": "كن أول من يعرف",
    "note": "اشترك بنشرتنا الإخبارية لتصلك أجدد المنتجات و عروض خاصة",
    "email": "العنوان الالكتروني",
    "addYourEmail": "أضف عنوانك الالكتروني",
    "changeLang": "تغيير اللغه",
    "partnerBrand": "العلامة التجارية الشريكة",
    "success": "نجاح",
    "newsLetterSuccess": "تم التسجيل بنجاح!",
    "error": "خطأ في الاشتراك",
    "returns": "الاسترجاع",
    "appName": "بارفوا",
    "send": "ارسال",
    "subscribeCta": "اشترك",
    "invalidEmail": "البريد الالكتروني غير صحيح"
  }
}
</i18n>
