<template>
  <div class="sticky-bar w-full text-xs">
    <div class="text-primary-1-100 [ w-full grid grid-cols-5 justify-between ] [ p-6 pt-0 ]">
      <AppLink to="/" class="flex items-center flex-col text-center" aria-label="home">
        <DynamicIcon
          :name="
            [/index___en/, /index___ar/].some(route => route.test($route.name?.toString() || '')) &&
            !categoryDialogOpen &&
            !accountDialogOpen
              ? 'home-active'
              : 'home'
          "
          :width="24"
          :height="24"
        />
        <span class="mt-2 px-1.5" :class="{ 'font-normal': categoryDialogOpen || accountDialogOpen }">
          {{ $t('home') }}</span
        >
      </AppLink>

      <button
        class="categories flex items-center flex-col text-center"
        :class="{ active: (/category/.test($route.path) || categoryDialogOpen) && !accountDialogOpen }"
        disable-hover
        @click="toggleCategoryMenu(true)"
      >
        <DynamicIcon
          :name="
            (/category/.test($route.path) || categoryDialogOpen) && !accountDialogOpen
              ? 'categories-active'
              : 'categories'
          "
          :width="24"
          :height="24"
        />
        <span class="mt-2 px-1.5" :class="{ 'font-normal': accountDialogOpen }"> {{ $t('categories') }}</span>
      </button>

      <AppLink to="/cart" class="flex flex-col items-center text-center relative" aria-label="navigate to cart">
        <DynamicIcon
          :name="
            /cart/.test($route.path) && !categoryDialogOpen && !accountDialogOpen
              ? 'responsive-cart-active'
              : 'responsive-cart'
          "
          :width="24"
          :height="24"
        />
        <span class="mt-2 px-1.5" :class="{ 'font-normal': categoryDialogOpen || accountDialogOpen }">
          {{ $t('cart') }}
          <span v-if="count"> ({{ count }}) </span>
        </span>
      </AppLink>

      <AppLink to="/deals" class="flex items-center flex-col text-center" aria-label="navigate to deals">
        <DynamicIcon
          :name="/deals/.test($route.path) && !categoryDialogOpen && !accountDialogOpen ? 'sale-active-dark' : 'sale'"
          :width="24"
          :height="24"
        />
        <span class="mt-2 px-1.5" :class="{ 'font-normal': categoryDialogOpen || accountDialogOpen }">
          {{ $t('offers') }}</span
        >
      </AppLink>

      <ClientOnly>
        <button
          class="Profile flex items-center flex-col text-center"
          aria-label="navigate to user"
          :class="{ active: (/account/.test($route.path) || accountDialogOpen) && !categoryDialogOpen }"
          @click="toAccount()"
        >
          <DynamicIcon
            :name="(/account/.test($route.path) || accountDialogOpen) && !categoryDialogOpen ? 'user-active' : 'user'"
            :class="{
              'text-gray-200': !/account/.test($route.path),
              'text-primary-1-100': /account/.test($route.path) && !categoryDialogOpen,
            }"
            :width="24"
            :height="24"
          />
          <span class="mt-2 px-1.5 whitespace-nowrap" :class="{ 'font-normal': categoryDialogOpen }">
            {{ user ? $t('profile') : $t('login') }}</span
          >
        </button>
      </ClientOnly>
    </div>
  </div>
</template>
<script setup lang="ts">
const { user } = useAuth();
const { count } = useCartAttributes();
const { emit } = useEventBus();
const { redirect } = useAppRouter();
const route = useRoute();

/**
 * incase user opened the category or account dialog
 * this will be used to switch between active and inactive icon
 */
const categoryDialogOpen = ref(false);
const accountDialogOpen = ref(false);

/**
 * function will be used to change categoryDialogOpen value, and notify listeners.
 */
function toggleCategoryMenu(value: boolean) {
  emit('TOGGLE_CATEGORY_MENU', value);
}

/**
 * function will be used to change accountDialogOpen value, and notify listeners.
 */
function toggleAccountMenu(value: boolean) {
  emit('TOGGLE_ACCOUNT_MENU', value);
}

/**
 * listen to that event and change the categoryDialogOpen according to value
 */
useEvent('TOGGLE_CATEGORY_MENU', (value: boolean) => {
  categoryDialogOpen.value = value;
  if (categoryDialogOpen.value) toggleAccountMenu(false);
});

/**
 * listen to that event and change the accountDialogOpen according to value
 */
useEvent('TOGGLE_ACCOUNT_MENU', (value: boolean) => {
  accountDialogOpen.value = value;
});

watch(
  () => route.name,
  () => {
    toggleCategoryMenu(false);
    toggleAccountMenu(false);
  }
);

const { t: $t } = useI18n({
  useScope: 'local',
});

function toAccount() {
  toggleCategoryMenu(false);
  if (user.value) {
    toggleAccountMenu(true);
    return;
  }
  redirect('/login');
}
</script>
<i18n>
{
  "en": {
    "login": "My Account",
    "profile": "Profile",
    "cart": "Cart",
    "home": "Home",
    "offers": "Sale",
    "categories": "Categories"
  },
  "ar": {
    "login": "تسجيل الدخول",
    "profile": "الملف الشخصي",
    "cart": "السلة ({count})",
    "home": "الرئيسية",
    "offers": "عروض",
    "categories": "الفئات"
    }
}
</i18n>
<style lang="postcss" scoped>
.nuxt-link-exact-active svg {
  @apply text-primary-1-100;
}

a[aria-label^='navigate to'].router-link-active svg {
  @apply text-primary-1-100 font-semibold;
}

.Profile {
  @apply whitespace-nowrap;
}

.curve-icon {
  transform: translateY(calc(-100% + 2px));
  filter: drop-shadow(-3px -4px 3px rgba(215, 215, 215, 0.3));
  z-index: -1;
}

.AppButton {
  @apply font-normal;
  border: none;
  padding: 0;
}
.sticky-bar {
  box-shadow: 0px -10px 30px rgba(26, 25, 25, 0.05);
  background: #fff;
}

.sticky-bar > div {
  & > a,
  & > button {
    @apply pt-4;
  }

  & > a[aria-label^='navigate to'].router-link-active,
  & > a.router-link-exact-active,
  .active {
    @apply font-semibold;
  }
}

[dir='rtl'] {
  .Profile {
    @apply whitespace-normal;
  }

  a[aria-label^='navigate to'].Cart.nuxt-link-active svg,
  & > a[aria-label^='navigate to'].Cart.nuxt-link-exact-active svg,
  & > a[aria-label^='navigate to'].Cart svg {
    transform: scale(-1, 1);
  }
}

:deep(.nuxt-icon svg) {
  height: 24px;
  width: 24px;
}

/* START OF CURVED CUTOUT STYLES */
/**
  * How To Make a Curved Cutout on Any Element in CSS
  * https://betterprogramming.pub/how-to-make-a-curved-cutout-to-any-element-74dbdc6bab56
  **/
</style>
